import React, { useState, useEffect, useRef, useCallback } from 'react';
import SearchButton from '../Images/SearchButton.svg';
import PurgeUserButton from '../Images/PurgeUser.svg';
import RestoreUserButton from '../Images/RestoreUser.svg';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';
import { useToastContext } from './Toast';
import '../App.css';

const Uploaded_Media = () => {
    const [pubkeyInput, setPubkeyInput] = useState('');
    const { sendWarning, sendSuccess } = useToastContext();
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mediaData, setMediaData] = useState({});
    const [mediaGroups, setMediaGroups] = useState([]);
    const [mediaUntil, setMediaUntil] = useState(Math.floor(Date.now() / 1000));
    const [mediaUntilPubkey, setMediaUntilPubkey] = useState(Math.floor(Date.now() / 1000));
    const [hasMore, setHasMore] = useState(true);
    const [selectedClass, setSelectedClass] = useState('any');
    const [appliedClass, setAppliedClass] = useState('any');
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, selectedMedia: null });
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [origin, setOrigin] = useState("upload");
    const [seenMediaKeys, setSeenMediaKeys] = useState(new Set());
    const [showTooltip, setShowTooltip] = useState(() => {
        const stored = localStorage.getItem('showTooltip');
        return stored === 'true'; // default to false
    });    
    const [hoveredMedia, setHoveredMedia] = useState(null);
    const mainContainerRef = useRef(null);
    const observer = useRef(null);

    const getHexPubkey = (input) => {
        return input.startsWith("npub") ? npubDecode(input) : input;
    };    

    const Bech32MaxSize = 5000;

    const handleInputChange = (e) => {
        setPubkeyInput(e.target.value);
    };

    const npubDecode = (nip19) => {
        try {
            const { prefix, words } = bech32.decode(nip19, Bech32MaxSize);
            const data = new Uint8Array(bech32.fromWords(words));
            if (prefix !== 'npub') throw new Error('Invalid prefix');
            return bytesToHex(data);
        } catch (error) {
            console.error('Error decoding npub:', error);
            return '';
        }
    };

    useEffect(() => {
        localStorage.setItem('showTooltip', showTooltip);
    }, [showTooltip]);
    

    const bytesToHex = (bytes) => {
        return Array.from(bytes)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
    };

    const hexToNpub = (hex) => {
        const bytes = hexToBytes(hex);
        return bech32.encode('npub', bech32.toWords(bytes), Bech32MaxSize);
    };

    const fetchUserInfo = async (npub) => {
        const hexPubkey = getHexPubkey(pubkeyInput);
        const payload = JSON.stringify(["user_infos", { "pubkeys": [hexPubkey] }]);
        try {
            const response = await fetch("https://dev.primal.net/api", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payload,
            });
            if (response.ok) {
                const data = await response.json();
                if (data && Array.isArray(data) && data.length > 0) {
                  const userData = JSON.parse(data[0].content);
                  setUserInfo(userData);
                  return userData;
                }
            } else {
                console.error("Failed to fetch user info:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
        return null;
    };

    const handleBanUser = async (e) => {
        e.preventDefault();
        const userInfo = await fetchUserInfo(pubkeyInput);
        if (!userInfo) {
            sendWarning('Failed to fetch user details.');
            return;
        }
        const confirmMessage = `Do you want to ban ${userInfo.display_name}, ${userInfo.nip05}?`;
        if (!window.confirm(confirmMessage)) {
            return;
        }
        sendWarning("Please wait...");
        try {
            const hexPubkey = getHexPubkey(pubkeyInput);
            const event = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'purge uploads request',
            });
            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['purge_uploads', {pubkey: hexPubkey}],
                }),
            });
            if (!response.ok) throw new Error('Failed to fetch data from the API');
            const result = await response.json();
            if (result.status) {
                sendSuccess('Uploads successfully purged for the user.');
            } else {
                throw new Error(result.message || 'Failed to purge uploads');
            }
        } catch (error) {
            console.error('Error in purging uploads:', error);
            sendWarning(error.message || 'Failed to purge uploads');
        } finally {
            setLoading(false);
        }
    };

    const fetchAvailableMediaGroups = async () => {
        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(Date.now() / 1000),
                kind: 10000222,
                tags: [],
                content: 'fetch available media groups',
            });
    
            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['available_media_groups', {}],
                }),
            });
    
            const result = await response.json();
    
            if (Array.isArray(result.groups)) {
                setMediaGroups(result.groups);
            } else {
                console.warn('Unexpected response structure from available_media_groups');
            }
        } catch (error) {
            console.error('Error fetching available media groups:', error);
            sendWarning('Failed to load media groups');
        }
    };    

    useEffect(() => {
        fetchAvailableMediaGroups();
    }, []);    

    const SearchedMediaData = async (isPaging = false) => {
        if (!pubkeyInput.trim()) {
            sendWarning("Please enter a valid npub.");
            return;
        }

        setLoading(true);
        if (!isPaging) {
            setMediaData({});
            setSeenMediaKeys(new Set());
        }
        const hexPubkey = getHexPubkey(pubkeyInput);

        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'fetch uploaded media for specific pubkey',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['uploaded_media', { pubkey: hexPubkey, until: mediaUntilPubkey, limit: 50 }],
                }),
            });

            if (!response.ok) throw new Error('Failed to fetch media data');
            const result = await response.json();

            if (result.uploads && Array.isArray(result.uploads) && result.uploads.length > 0) {
                setMediaData(prev => groupMediaByDate(prev, result.uploads));
                setMediaUntilPubkey(result.uploads[result.uploads.length - 1].created_at);
                setHasMore(result.uploads.length === 50);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching media data:', error);
            sendWarning(error.message || 'Failed to fetch media data');
        } finally {
            setLoading(false);
        }
    };

    const resetPagination = () => {
        setMediaData({});
        setMediaUntil(Math.floor(Date.now() / 1000));
        setHasMore(true);
    };


    const groupMediaByDate = (prevData, newMedia) => {
        if (!newMedia || !Array.isArray(newMedia)) return prevData;
    
        const grouped = { ...prevData };
        const newKeys = new Set(seenMediaKeys);
        let hasNew = false;
    
        newMedia.forEach(media => {
            const key = `${media.sha256}-${media.pubkey}-${media.created_at}`;
            if (newKeys.has(key)) return;
    
            hasNew = true;
            newKeys.add(key);
            const date = new Date(media.created_at * 1000).toDateString();
            if (!grouped[date]) grouped[date] = [];
            grouped[date].push(media);
        });
    
        if (hasNew) {
            setSeenMediaKeys(newKeys);
        }
    
        return grouped;
    };
    

    const lastMediaElementRef = useCallback(
    (node) => {
        if (loading || isFetchingMore || !hasMore) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                console.log("✅ Observer triggered at last media element. Fetching more...");
                fetchMediaData(true);
            }
        });

        if (node) {
            console.log("👀 Observer attached to:", node);
            observer.current.observe(node);
        }
    },
    [loading, isFetchingMore, hasMore, mediaUntil]
);

const fetchMediaData = async (isPaging = false, classFilter = appliedClass, fetchOrigin = origin) => {
    if (loading || isFetchingMore || !hasMore) return;

    if (isPaging) {
        setIsFetchingMore(true);
    } else {
        setLoading(true);
        setMediaUntil(Math.floor(Date.now() / 1000));
    }

    console.log(`📡 Fetching media... isPaging: ${isPaging}, until: ${mediaUntil}, class: ${classFilter}, origin: ${fetchOrigin}`);

    try {
        const event = await window.nostr.signEvent({
            created_at: Math.floor(Date.now() / 1000),
            kind: 10000222,
            tags: [],
            content: 'fetch uploaded media',
        });

        const response = await fetch('https://admin.primal.net/api2', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                event_from_user: event,
                request: [
                    'uploaded_media',
                    {
                        origin: fetchOrigin,
                        class: classFilter,
                        since: 0,
                        until: isPaging ? mediaUntil : Math.floor(Date.now() / 1000),
                        limit: 50,
                    }
                ],
            }),
        });

        const result = await response.json();

        console.log("🛠️ API Response:", result);

        if (result.uploads && Array.isArray(result.uploads)) {
            console.log(`📦 Received ${result.uploads.length} media items`);

            if (result.uploads.length === 0) {
                console.warn("🚫 API returned no more media items. Stopping fetch.");
                setHasMore(false);
                return;
            }

            setMediaData(prev => ({ ...prev, ...groupMediaByDate(prev, result.uploads) }));

            const lastItem = result.uploads[result.uploads.length - 1];
            console.log("🔄 Updating mediaUntil:", lastItem.created_at);
            setMediaUntil(lastItem.created_at);
            setHasMore(true);
        } else {
            console.warn("⚠️ API response format is unexpected.");
            setHasMore(false);
        }
    } catch (error) {
        console.error('🚨 Error fetching media:', error);
        sendWarning(error.message || 'Failed to fetch media');
    } finally {
        setIsFetchingMore(false);
        setLoading(false);
    }
};

    const purgeUserByPubkey = async (hexPubkey) => {
        if (!hexPubkey) {
            console.error("❌ No valid pubkey provided.");
            sendWarning("No valid pubkey provided.");
            return;
        }

        console.log(`🛑 Purging uploads for pubkey: ${hexPubkey}`);

        sendWarning("Please wait...");
        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(Date.now() / 1000),
                kind: 10000222,
                tags: [],
                content: 'purge uploads request',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['purge_uploads', { pubkey: hexPubkey }],
                }),
            });

            if (!response.ok) throw new Error('Failed to fetch data from the API');
            const result = await response.json();

            if (result.status) {
                sendSuccess('Uploads successfully purged for the user.');
            } else {
                throw new Error(result.message || 'Failed to purge uploads');
            }
        } catch (error) {
            console.error('Error in purging uploads:', error);
            sendWarning(error.message || 'Failed to purge uploads');
        } finally {
            setLoading(false);
        }
    };

  const handlePurgeUser = async (e) => {
    e.preventDefault();

    if (!contextMenu.selectedMedia || !contextMenu.selectedMedia.pubkey) {
        console.error("❌ No pubkey found for selected media.");
        sendWarning("No pubkey found for this media.");
        return;
    }

    const pubkey = contextMenu.selectedMedia.pubkey;
    console.log(`🛑 Sending hex pubkey to purge uploads: ${pubkey}`);

    await purgeUserByPubkey(pubkey);
};

    const handleApplyFilters = () => {
        setMediaData({});
        setSeenMediaKeys(new Set());
        setMediaUntil(Math.floor(Date.now() / 1000));
        setHasMore(true);

        setAppliedClass(selectedClass);
        setOrigin((prevOrigin) => {
            fetchMediaData(false, selectedClass, prevOrigin);
            return prevOrigin;
        });
    };

    useEffect(() => {
        if (appliedClass) {
            fetchMediaData(false);
        }
    }, [appliedClass]);

    const handleContextMenu = (e, media) => {
        e.preventDefault();

        if (!media.pubkey) {
            console.error("❌ No pubkey found in selected media.");
            return;
        }

        const menuWidth = 180;
        const menuHeight = 120;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        let clickX = e.clientX;
        let clickY = e.clientY;

        if (clickX + menuWidth > windowWidth) {
            clickX = windowWidth - menuWidth - 10;
        }

        if (clickY + menuHeight > windowHeight) {
            clickY = windowHeight - menuHeight - 10;
        }

        setContextMenu({
            visible: true,
            x: clickX,
            y: clickY,
            selectedMedia: media,
        });
        console.log(`📌 Context menu opened at X: ${clickX}, Y: ${clickY}`);
    };

    const handleClick = (e, media) => {
        e.preventDefault();
        handleContextMenu(e, media);
    };

    const handleCloseContextMenu = () => {
        setContextMenu({ visible: false, x: 0, y: 0, selectedMedia: null });
    };

    const handleProfileClick = () => {
        if (contextMenu.selectedMedia) {
            window.open(`https://primal.net/p/${hexToNpub(contextMenu.selectedMedia.pubkey)}`, '_blank');
        }
    };

    const handlePurgeImageClick = async () => {
        if (!contextMenu.selectedMedia) return;

        const url = contextMenu.selectedMedia.url;
        const page_url = window.location.href;
        const comment = prompt("Please enter a comment:", "");

        if (!comment) {
            sendWarning('Purging image cancelled. No comment provided.');
            return;
        }

        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'purge image request',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['purge_image', { url, page_url, comment }],
                }),
            });

            const result = await response.json();
            if (result.status) {
                sendSuccess('Image successfully purged.');
                const updatedMedia = mediaData.map((media) => {
                    if (media.url === url) {
                        return { ...media, purged: true };
                    }
                    return media;
                });
                setMediaData(updatedMedia);
            } else {
                throw new Error(result.logs || 'Failed to purge image');
            }
        } catch (error) {
            console.error('Error purging image:', error);
            sendWarning(error.message || 'Failed to purge image');
        }
    };

    const handleRestoreUser = async (e) => {
        e.preventDefault();
    
        const userInfo = await fetchUserInfo(pubkeyInput);
        if (!userInfo) {
            sendWarning('Failed to fetch user details.');
            return;
        }
    
        const confirmMessage = `Do you want to restore uploads for ${userInfo.display_name}, ${userInfo.nip05}?`;
        if (!window.confirm(confirmMessage)) {
            return;
        }
    
        sendWarning("Please wait...");
    
        try {
            const hexPubkey = getHexPubkey(pubkeyInput);
    
            const event = await window.nostr.signEvent({
                created_at: Math.floor(Date.now() / 1000),
                kind: 10000222,
                tags: [],
                content: 'restore uploads request',
            });
    
            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['restore_user_uploads', { pubkey: hexPubkey }],
                }),
            });
    
            if (!response.ok) throw new Error('Failed to send request to the API');
    
            const result = await response.json();
    
            if (result.status) {
                sendSuccess('Uploads successfully restored for the user.');
            } else {
                throw new Error(result.message || 'Failed to restore uploads');
            }
        } catch (error) {
            console.error('Error in restoring uploads:', error);
            sendWarning(error.message || 'Failed to restore uploads');
        } finally {
            setLoading(false);
        }
    };

    const handleRestoreMedia = async () => {
        const media = contextMenu.selectedMedia;
        if (!media?.media_block_id) {
            sendWarning("Missing media block ID.");
            handleCloseContextMenu();
            return;
        }
    
        const confirmMessage = "Do you want to restore this blocked media item?";
        if (!window.confirm(confirmMessage)) {
            handleCloseContextMenu();
            return;
        }
    
        sendWarning("Please wait...");
    
        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(Date.now() / 1000),
                kind: 10000222,
                tags: [],
                content: 'restore media upload request',
            });
    
            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['restore_upload', { media_block_id: media.media_block_id }],
                }),
            });
    
            const result = await response.json();
    
            if (result.status) {
                sendSuccess("Media successfully restored.");
                // Optionally refresh media list here
            } else {
                throw new Error(result.message || "Failed to restore media");
            }
        } catch (error) {
            console.error("Error restoring media:", error);
            sendWarning(error.message || "Failed to restore media");
        } finally {
        }
    };
    
    

    const handleCopyDescription = () => {
        if (!contextMenu.selectedMedia || !contextMenu.selectedMedia.description) {
            sendWarning("No content");
            return;
        }

        navigator.clipboard.writeText(contextMenu.selectedMedia.description)
            .then(() => {
                sendSuccess("Description copied to clipboard");
            })
            .catch((error) => {
                console.error("Error copying description:", error);
                sendWarning("Failed to copy description");
            });
    };
    
    const withContextMenuClose = (fn) => async (...args) => {
        try {
            await fn(...args);
        } finally {
            handleCloseContextMenu();
        }
    };
    

    const handleSetUploadMetadata = async (metadata) => {
        const media = contextMenu.selectedMedia;
        if (!media?.url) {
            sendWarning("Media URL is missing.");
            handleCloseContextMenu();
            return;
        }
    
        sendWarning("Please wait...");
    
        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(Date.now() / 1000),
                kind: 10000222,
                tags: [],
                content: 'set upload metadata request',
            });
    
            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['set_upload_metadata', {
                        url: media.url,
                        md: metadata
                    }]
                }),
            });
    
            const result = await response.json();
    
            if (result.status) {
                sendSuccess("Metadata successfully updated.");
            } else {
                throw new Error(result.message || "Failed to set metadata");
            }
        } catch (error) {
            console.error("Error setting metadata:", error);
            sendWarning(error.message || "Failed to set metadata");
        }
    };
    

    useEffect(() => {
        const handleScroll = () => {
          if (contextMenu.visible) {
            handleCloseContextMenu();
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [contextMenu.visible]);

      useEffect(() => {
        const container = mainContainerRef.current;
        if (!container) return;

        const handleScroll = () => {
          if (contextMenu.visible) {
            handleCloseContextMenu();
          }
        };

        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
      }, [contextMenu.visible]);


      const renderMediaSections = () => {
        if (!mediaData || Object.keys(mediaData).length === 0) {
            return <div>No media available.</div>;
        }

        const dates = Object.keys(mediaData).sort((a, b) => new Date(b) - new Date(a));

        return dates.map((date, dateIndex) => (
            <div key={date}>
                <h2 className="MediaUploadDate">{date}</h2>
                <div className="MediaDisplay">
                    {mediaData[date].map((media, index) => {
                        const isLastElement = dateIndex === dates.length - 1 && index === mediaData[date].length - 1;
                        const isVideo = media.mimetype && media.mimetype.startsWith("video");

                        let mediaSrc = media.thumbnail_url;
                        if (isVideo && mediaSrc && mediaSrc.endsWith(".mov")) {
                            mediaSrc = "https://via.placeholder.com/150";
                        }

                        const openMediaInNewTab = (e) => {
                            e.preventDefault();
                            const mediaUrl = media.url || media.thumbnail_url;
                            if (mediaUrl) {
                                window.open(mediaUrl, "_blank");
                            }
                        };

                        return (
                            <div
                                ref={isLastElement ? lastMediaElementRef : null}
                                key={index}
                                className="MediaItems"
                                onClick={openMediaInNewTab}
                                onContextMenu={(e) => handleContextMenu(e, media)}
                                onMouseEnter={() => setHoveredMedia({
                                    description: media.description || "No description",
                                    createdAt: new Date(media.created_at * 1000).toLocaleString()
                                })}
                                onMouseLeave={() => setHoveredMedia(null)}
                            >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                    {!isVideo ? (
                                        <img
                                            src={mediaSrc || "https://via.placeholder.com/150"}
                                            alt="Uploaded Media"
                                            className="MediaThumbnail"
                                        />
                                    ) : (
                                        <video
                                            className="MediaThumbnail"
                                            src={media.url}
                                            poster={mediaSrc}
                                            muted
                                            loop
                                            preload="metadata"
                                            onMouseEnter={(e) => e.target.play()}
                                            onMouseLeave={(e) => e.target.pause()}
                                        />
                                    )}
                                    {isVideo && (
                                        <div className="VideoIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                                                <path fill="white" d="M8 5v14l11-7z"></path>
                                            </svg>
                                        </div>
                                    )}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        ));
    };

    const isBlockedClass = ["auto-csam-blocked", "manually-blocked", "blocked"].includes(appliedClass);
    const showNSFWOptions = ["any", "memes", "sfw"].includes(appliedClass);
    const showSFWOption = appliedClass === "nsfw";
    const filteredClassOptions = mediaGroups.filter(group => group.origin === origin);



    const renderContextMenu = () => {
        if (!contextMenu.visible) return null;
        const { selectedMedia } = contextMenu;
    
        return (
            <div
                className="contextMenu1"
                style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="contextMenuContent">
                <button onClick={withContextMenuClose(handleProfileClick)}>Open Profile</button>
                <button onClick={withContextMenuClose(handlePurgeImageClick)}>Block Image</button>
                <button onClick={withContextMenuClose(handlePurgeUser)}>Block User</button>
                <button onClick={withContextMenuClose(handleCopyDescription)}>Copy Description</button>
                {selectedMedia?.note_id && (
                    <button onClick={withContextMenuClose(() => {
                        window.open(`https://primal.net/e/${selectedMedia.note_id}`, "_blank");
                    })}>
                        Open Note
                    </button>
                )}
                {isBlockedClass && selectedMedia?.media_block_id && (
                    <button onClick={withContextMenuClose(handleRestoreMedia)}>Restore</button>
                )}
                {showNSFWOptions && (
                    <>
                        <button onClick={withContextMenuClose(() => handleSetUploadMetadata({ nsfw: 1.0 }))}>Mark as NSFW</button>
                        <button onClick={withContextMenuClose(() => handleSetUploadMetadata({ csam: 1.0 }))}>Mark as CSAM</button>
                        <button onClick={withContextMenuClose(() => handleSetUploadMetadata({ meme: 1.0 }))}>Mark as Meme</button>
                    </>
                )}
                {showSFWOption && (
                    <button onClick={withContextMenuClose(() => handleSetUploadMetadata({ nsfw: 0.0 }))}>Mark as SFW</button>
                )}
                </div>
            </div>
        );
    };
    

    return (
        <div className="MainBody" onClick={handleCloseContextMenu} ref={mainContainerRef}>
                <div className="UMTitle">Uploaded Media</div>
            {showTooltip && hoveredMedia && (
                <div className="floatingTooltip">
                    <table className="tooltipTable">
                    <tbody>
                        <tr>
                        <td
                            style={{
                            fontWeight: 'bold',
                            paddingRight: '8px',
                            whiteSpace: 'nowrap',
                            verticalAlign: 'top',
                            }}
                        >
                            Created At:
                        </td>
                        <td>{hoveredMedia.createdAt}</td>
                        </tr>
                        {hoveredMedia.description?.split('\n')
                        .filter(line => line.trim() && !line.toLowerCase().startsWith("description:"))
                        .map((line, index) => {
                            const [key, ...rest] = line.split(':');
                            const value = rest.join(':').trim();
                            return (
                            <tr key={index}>
                                <td
                                style={{
                                    fontWeight: 'bold',
                                    paddingRight: '8px',
                                    whiteSpace: 'nowrap',
                                    verticalAlign: 'top',
                                }}
                                >
                                {key.trim()}:
                                </td>
                                <td style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{value}</td>
                            </tr>
                            );
                        })}
                    </tbody>
                    </table>
                </div>
                )}
            <form className="BanUserForm" onSubmit={e => e.preventDefault()}>
                <label className="label">
                    Search by User (pubkey)
                    <input
                        type="text"
                        className="SearchBanUser"
                        value={pubkeyInput}
                        onChange={handleInputChange}
                    />
                </label>
                <button type="button" onClick={() => SearchedMediaData(false)} className="SearchBanButton">
                    <img src={SearchButton} alt="Search Button" />
                </button>
                <button type="button" onClick={handleBanUser} className="BanSearchButton">
                    <img src={PurgeUserButton} alt="Ban User Button" />
                </button>
                <button type="button" onClick={handleRestoreUser} className="BanSearchButton">
                    <img src={RestoreUserButton} alt="Restore User Button" />
                </button>
            </form>
            <div className="DropdownContainer">
                 <select
                    value={selectedClass}
                    onChange={(e) => setSelectedClass(e.target.value)}
                >
                    {filteredClassOptions.map((group) => (
                        <option key={group.class} value={group.class}>
                            {group.label}
                        </option>
                    ))}
                </select>



                <select
                    value={origin}
                    onChange={(e) => {
                        const newOrigin = e.target.value;
                        setOrigin(newOrigin);
                        if (newOrigin === "nostr") {
                            setSelectedClass("any");
                        }
                    }}
                >
                    <option value="upload">Primal Only</option>
                    <option value="nostr">All Of Nostr</option>
                </select>

                <button className="ApplyFilterButton" onClick={handleApplyFilters}>
                    Apply Filters
                </button>
                <div className="CustomCheckboxCC">
                <label className="CustomCheckbox">
                        <span>Tooltip</span>
                        <input
                            type="checkbox"
                            checked={showTooltip}
                            onChange={() => setShowTooltip(prev => !prev)}
                        />
                        <span className="checkmark"></span>
                    </label>
                    </div>
            </div>
            <div className="MediaContent">
            {loading ? (
                <div className="loader">
                    <span></span>
                </div>
            ) : (
                Object.keys(mediaData).length > 0 ? renderMediaSections() : <div className="NoMedia">No media available</div>
            )}
        </div>
                    {!hasMore && <div></div>}
                    {renderContextMenu()}
        </div>
    );
};

export default Uploaded_Media;
